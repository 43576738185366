<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <!--            <el-button type="primary" plain size="mini" v-if="tenantManagementSearchFlag" @click="tenantManagementSearch">查询</el-button>-->
            <el-button type="primary" plain size="mini" v-if="tenantManagementAddFlag" @click="tenantManagementAdd">新增</el-button>
            <el-button type="primary" plain size="mini" v-if="tenantManagementModifyFlag" @click="tenantManagementModify">修改</el-button>
            <el-button type="primary" plain size="mini" v-if="tenantManagementDelFlag" @click="tenantManagementDel">删除</el-button>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-input maxlength="50" placeholder="请输入库房名称（模糊匹配）" v-model="AccurateAndUnique_tenantName" size="mini" style="width: 250px"  class="input-with-input">
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
          </div>
        </el-col>
        <el-drawer
            title="高级查询条件"
            :before-close="cancelForm"
            :visible.sync="dialog"
            direction="rtl"
            custom-class="demo-drawer"
            ref="drawer"
        >
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                        <el-form-item label="库房编码" prop="houseId">
                          <el-input  maxlength="18" v-model="dataForms.houseId"></el-input>
                        </el-form-item>
                        <el-form-item label="库房名称" prop="houseName">
                          <el-input v-model="dataForms.houseName" placeholder="支持模糊匹配"></el-input>
                        </el-form-item>
                        <el-form-item label="库房地址" prop="houseAddress">
                          <el-input v-model="dataForms.houseAddress" placeholder="支持模糊匹配"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="是否启用" prop="status">
                          <el-select v-model="dataForms.status" placeholder="请选择" style="width: 100%">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="冻结" value="0"></el-option>
                            <el-option label="启用" value="1"></el-option>
                          </el-select>
                        </el-form-item> -->
                      </el-form>
                      <div slot="footer" class="dialog-footer" style="text-align: center">
                        <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                        <el-button size="mini" v-if="tenantManagementSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                        <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>

            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain">
      <ces-table
          size='mini'
          :isSelection='true'
          :isIndex='true'
          :isPagination='true'
          :isHandle='true'
          v-loading='loading'
          :tableData='tableData'
          :tableCols='tableCols'
          :pagination='pagination'
          @refresh="handleCurrentChange"
          ref='cesTable'
      >
      </ces-table>
      <!-- @handleClosButtonClick="handleClosButtonClick"-->
    </div>
    <div>
      <el-dialog
          title="分配菜单"
          :visible.sync="dialogVisibleMenu"
          :close-on-click-modal="false"
          :before-close="tree_handleClose"
          v-if="addDialogVisible"
          width="30%">

        <el-tree
            :data="menuTreeList"
            show-checkbox
            check-strictly
            node-key="menuId"
            ref="tree"
            @check-change="checkChange"
            highlight-current
            :default-expanded-keys=expandTreeNodesByDefault
            :props="defaultProps">
        </el-tree>
        <div style="margin-top: 20px" align="center">
          <el-button type="primary" @click="handleSaveMenu()">保存</el-button>
          <el-button @click="handleClearMenu()">清空</el-button>
        </div>
      </el-dialog>
    </div>
    <!--    库房-->
    <el-dialog
        :title="dialogtenantAPPovaltitile"
        :visible.sync="dialog_tenantAPPoval"
        width="75%"
        :modal="true"
        center
        :before-close="handleClose_tenantAPPoval">
      <el-card class="box-card" :body-style="{ padding: '10px' }">
        <div slot="header">
          <span style="font-size: 14px">库房信息</span>
        </div>
        <div>
          <el-form :model="tenantAPPovalForm"  ref="tenantAPPovalForm" :rules="tenantAPPovalFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
            <el-row>
              <el-col   :span="8">
                <el-form-item  label="库房编码" prop="houseId"  >
                  <el-input :disabled="dialogtenantAPPovaltitile === '修改库房'"
                            v-model="tenantAPPovalForm.houseId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="库房名称" prop="houseName">
                  <el-input v-model="tenantAPPovalForm.houseName" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="库房地址" prop="houseAddress">
                  <el-input v-model="tenantAPPovalForm.houseAddress" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <CountriesLink @addlist="getCity" :addList="tenantAPPovalForm"></CountriesLink>

          </el-form>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_tenantAPPoval">提 交</el-button>
                <el-button @click="handleClose_tenantAPPoval">关 闭</el-button>
            </span>
    </el-dialog>


  </div>
</template>

<script>
//引入组件
import cesTable from '../../components/common/table.vue'
import Drawer from '../../components/common/drawer.vue'
import axios from 'axios'
import arrayQuery from '../../components/arrayQuery.js'
import CityLinkageReg from "../../components/common/cityLinkageReg.vue"
import CountriesLink from "../../components/common/countriesLink.vue"
import qs from 'qs'
import store from '../.././store/index'
import $ from 'jquery'
import 'element-ui/lib/theme-chalk/index.css'
export default {
  data(){
    return {
      table: false,
      dialog: false,
      loading: false,
      isFlag:true,
      tableDatadialog: [],
      dialogtenantAPPovaltitile:'',
      dialog_tenantAPPoval:false,
      tenantEnumFlag:false,
      AccurateAndUnique_tenantName:'',
      dataForms:{
        houseName:'',
        houseId:'',
        // status:'',
        houseAddress:''
      },
      tenantAPPovalForm:{
        houseId:'',
        houseName: '',
        // status:'',
        houseAddress:'',
        provinceCode:'',
        cityCode:'',
        districtCode:'',
        province: "",
        city: "",
        area: ""
      },
      addList: {
        provinceCode:'',
        cityCode:'',
        districtCode:'',
        province: "",
        city: "",
        area: ""
      },
      columns: [
        {
          id: "selection",
          type: "selection",
          label: "",
          fixed: "left",
          width: "55",
          prop: "",
          isShow: true,
          align: "center"
        },
        {
          id: "companyId",
          type: "",
          label: "编码",
          fixed: false,
          width: "120",
          prop: "companyId",
          isShow: true,
          align: "center"
        },
        {
          id: "companyName",
          type: "",
          label: "名称",
          fixed: false,
          width: "180",
          prop: "companyName",
          isShow: true,
          align: "center"
        },
        // {
        //   id: "companyType",
        //   type: "",
        //   label: "使用类型",
        //   fixed: false,
        //   width: "130",
        //   prop: "companyType",
        //   isShow: true,
        //   align: "center",
        //   formatter: (row) => {
        //     if (row.companyType == '1') {
        //       return "承运商/司机";
        //     } else if (row.companyType == '0') {
        //       return "普通用户";
        //     } else {
        //       return "";
        //     }
        //   }
        // },
        // {
        //   id: "moduleId",
        //   type: "",
        //   label: "所属模块",
        //   fixed: false,
        //   width: "130",
        //   prop: "moduleId",
        //   isShow: true,
        //   align: "center",
        //   formatter: (row) => {
        //     if (row.moduleId == '1') {
        //       return "城市配";
        //     } else if (row.moduleId == '0') {
        //       return "系统管理";
        //     } else {
        //       return "";
        //     }
        //   }
        // }
      ],
      pagesizes: [5, 10, 15, 20],
      tenantAPPovalFormrules:{
        address: [
          { required: true,validator: this.validateProvince, trigger: ['blur','change'] }
        ],
        houseId: [
          { required: true,message: '请输入库房编码', trigger: 'blur' }
        ],
        houseName: [
          { required: true,message: '请输入库房名称', trigger: 'blur' }
        ],
        houseAddress: [
          { required: true,message: '请输入库房地址', trigger: 'blur' }
        ],
        // status: [
        //   { required: true,message: '请选择是否启用', trigger: 'blur' }
        // ],
      },
      formLabelWidth: '80px',
      timer: null,
      apiId:'null',
      fieldList:[],
      // 表格
      tableData:[],//表格数据
      tableCols:[ //表格列数据
        {label:'库房编码',prop:'houseId',width:'120',align:'center'},
        {label:'库房名称',prop:'houseName',width:'120',align:'center'},
        {label:'库房地址',prop:'houseAddress',width:'350',align:'center'},
        {label:'省',prop:'provinceCode',width:'120',align:'center',
          // formatter: async (row) => {
          //   if(row.provinceCode) {
          //     let provinceData = await this.trans(row.provinceCode)
          //     console.log(
          //         provinceData
          //     )
          //     return provinceData
          //   }
          // }
        },
        {label:'市',prop:'cityCode',width:'200',align:'center'},
        {label:'区',prop:'districtCode',width:'200',align:'center'},
        // {label:'启用状态',prop:'status',align:'center',
        //   formatter: (row) => {
        //     if (row.status == 1) {
        //       return "启用";
        //     } else if (row.status == 0) {
        //       return "冻结";
        //     } else {
        //       return "";
        //     }
        //      }
        // },
        // {label:'创建人',prop:'creator',align:'center',width:'120'},
        // {label:'修改人',prop:'modifier',align:'center'},
        // {label:'创建时间',prop:'createTime',align:'center',width:'160',
        //   formatter: (row, column) => {
        //     if(row.createTime == null){
        //       return ''
        //     }else {
        //       return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
        //     }
        //   }
        // },
        // {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
        //   formatter: (row, column) => {
        //     if(row.modifyTime == null){
        //       return ''
        //     }else {
        //       return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
        //     }
        //   }
        // },
      ],
      pagination:{ //分页数据
        pageSize:10,
        pageNum:1,
        total:0
      },
      merge:[],
      dialogVisibleMenu:false,
      menuTreeList:[],
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      houseId:null,
      tenantManagementSearchFlag:true,
      tenantManagementAddFlag:true,
      tenantManagementModifyFlag:true,
      tenantManagementDelFlag:true,
      expandTreeNodesByDefault:[],
      addDialogVisible:false
    }
  },

  components:{ //引入组件后注册组件
    cesTable,
    Drawer,
    CityLinkageReg,
    CountriesLink
  },
  mounted(){
    this.getTableAndForm();
    this.getMenuTree();
    let parentId = '2_3_8';
    let currentPageButtons = arrayQuery.text(parentId);
    let _this = this;
    _this.currentPageButtons = currentPageButtons;
    //查询按钮权限
    let tenantManagementSearch = currentPageButtons.find(item => {
      return item.menuId == '2_3_8_1';
    });
    if(tenantManagementSearch == undefined){
      _this.tenantManagementSearchFlag = false;
    }else {
      _this.tenantManagementSearchFlag = true;
    }
    //新增按钮权限
    let tenantManagementAdd = currentPageButtons.find(item => {
      return item.menuId == '2_3_8_2';
    });
    if(tenantManagementAdd == undefined){
      _this.tenantManagementAddFlag = false;
    }else {
      _this.tenantManagementAddFlag = true;
    }
    //修改按钮权限
    let tenantManagementModify = currentPageButtons.find(item => {
      return item.menuId == '2_3_8_3';
    });
    if(tenantManagementModify == undefined){
      _this.tenantManagementModifyFlag = false;
    }else {
      _this.tenantManagementModifyFlag = true;
    }
    //删除按钮权限
    let tenantManagementDel = currentPageButtons.find(item => {
      return item.menuId == '2_3_8_4';
    });
    if(tenantManagementDel == undefined){
      _this.tenantManagementDelFlag = false;
    }else {
      _this.tenantManagementDelFlag = true;
    }
  },
  methods:{
    validateProvince(rules,value,callback){
      if(!this.tenantAPPovalForm.province){
        callback(new Error('请选择省'))
      }else{
        callback()
      }
    },
    tree_handleClose(){
      this.expandTreeNodesByDefault.length = 0;
      this.dialogVisibleMenu = false;
      this.addDialogVisible = false;
    },
    trans(tableData){
      tableData.forEach(e=>{
        axios.get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.provinceCode}&level=1`).then(res=>{
          e.provinceCode = res.data
        }).catch(err=>console.error(err))
      })
      tableData.forEach(e=>{
        if(e.cityCode){
          axios.get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.cityCode}&level=2`).then(res=>{
            e.cityCode = res.data
          }).catch(err=>console.error(err))
        }
      })
      tableData.forEach(e=>{
        if(e.districtCode){
          axios.get(`${this.$apiPath}/api/pm/region/getRegionName?adcode=${e.districtCode}&level=3`).then(res=>{
            e.districtCode = res.data
          }).catch(err=>console.error(err))
        }
      })
    },

    getCity(data) {
      this.tenantAPPovalForm.province = data.province;
      this.tenantAPPovalForm.city = data.city;
      this.tenantAPPovalForm.area = data.area;
    },
    // checkbox的触发接收
    handleSelectionChange(val) {
      this.companyData = val.val;
    },
    // 双击事件的行触发
    clickTable(val) {
      this.userManagementForm.companyId = val.row.companyId;
      this.userManagementForm.companyName = val.row.companyName;
      this.dialogTableVisible = false;
    },
    handleCurrentChange_compan(val) {
      this.tableDatadialog_pagination.pageNum = val;
      this.companySearch();
    },
    checkChange(data, check) {
      // 父节点操作
      if (data.parentId !== null) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.parentId, true);
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.parentId);
          var parentHasCheckedChild = false;
          for (
              var i = 0, parentChildLen = parentNode.childNodes.length;
              i < parentChildLen;
              i++
          ) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true;
              break;
            }
          }
          if (!parentHasCheckedChild)
            this.$refs.tree.setChecked(data.parentId, false);
        }
      }
      // 子节点操作，如果取消选中，取消子节点选中
      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].menuId);
          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.menuId, false);
          }
        }
      }
    },
    save_tenantAPPoval(){
      this.$refs["tenantAPPovalForm"].validate(valid => {
        if (valid) {

        } else {
          this.$message.error("请检查您输入的数据,请核对后重试!");
        }
      });
      // const data = new URLSearchParams();
      let params={
        'houseId':this.tenantAPPovalForm.houseId,
        'houseName':this.tenantAPPovalForm.houseName,
        'houseAddress':this.tenantAPPovalForm.houseAddress,
        'provinceCode':this.tenantAPPovalForm.province,
        'cityCode':this.tenantAPPovalForm.city,
        'districtCode':this.tenantAPPovalForm.area
      }

      if( this.dialogtenantAPPovaltitile == '新增库房'){
        //运单新增
        $.ajax({
          type: 'post',
          dataType:'json',
          contentType:'application/json',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization",
            "Authorization": this.$store.state.token
          },
          //设置允许ajax请求鞋带cookie即可
          xhrFields: {
            withCredentials: true
          },
          async:true,
          url: `${this.$apiPath}/api/urban/warehouse/addWarehouseInfo`,
          data: JSON.stringify(params),
          beforeSend: (request)=> {
            request.setRequestHeader("Authorization", this.$store.state.token);
            // request.setRequestHeader("token", this.$store.state.token);
          },
          success:  (res)=> {
            console.log(res)
            if(res.success == true){
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.handleClose_tenantAPPoval();
              this.tenantManagementSearch();
            }else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          }
        })

      }else if( this.dialogtenantAPPovaltitile == '修改库房'){
        // data.append('houseId', this.merge[0].houseId);
        params.houseId = this.merge[0].houseId
        $.ajax({
          type: 'post',
          dataType:'json',
          contentType:'application/json',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Authorization",
            "Authorization": this.$store.state.token
          },
          //设置允许ajax请求鞋带cookie即可
          xhrFields: {
            withCredentials: true
          },
          async:true,
          url: `${this.$apiPath}/api/urban/warehouse/updateWarehouseInfo`,
          data: JSON.stringify(params),
          beforeSend: (request)=> {
            request.setRequestHeader("Authorization", this.$store.state.token);
            // request.setRequestHeader("token", this.$store.state.token);
          },
          success:  (res)=> {
            console.log(res)
            if(res.success == true){
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.handleClose_tenantAPPoval();
              this.tenantManagementSearch();
            }else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          }
        })
        // axios({
        //   method: 'post',
        //   url: `${this.$apiPath}/api/urban/warehouse/updateWarehouseInfo`,
        //   data:data
        // }).then(res=> {
        //   if(res.data.success == true){
        //     this.$message({
        //       message: '修改成功',
        //       type: 'success'
        //     });
        //     this.handleClose_tenantAPPoval();
        //     this.tenantManagementSearch();
        //   }else {
        //     this.$alert(res.data.msg, '提示', {
        //       confirmButtonText: '确定',
        //     });
        //   }
        // });
      }
    },
    handleClose_tenantAPPoval(){
      this.dialog_tenantAPPoval = false;
      this.$refs.tenantAPPovalForm.resetFields();
      this.addList.provinceCode = ''
      this.addList.cityCode = ''
      this.addList.districtCode = ''
      this.tenantAPPovalForm.province = ''
      this.tenantAPPovalForm.city = ''
      this.tenantAPPovalForm.area = ''
      this.tenantManagementSearch();
    },
    //此方法是点击分页时触发的查询，
    handleCurrentChange(){
      this.tenantManagementSearch()
    },
    //获取表格和表单数据
    async getTableAndForm(){
      this.tenantManagementSearch()
    },
    handleQuery(){ //查询
      this.pagination.pageSize = 10;
      this.pagination.pageNum = 1;
      this.pagination.total = 0;
      //快捷查询
      this.loading = true;
      // const data = new URLSearchParams();
      let params = {
        houseName:this.AccurateAndUnique_tenantName,
        pageSize:this.pagination.pageSize,
        pageNo:this.pagination.pageNum
      }
      console.log(this.$store.state.token)
      $.ajax({
        type: 'post',
        dataType:'json',
        contentType:'application/json',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          "Authorization": this.$store.state.token
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true
        },
        async:true,
        url: `${this.$apiPath}/api/urban/warehouse/queryWarehousesPage`,
        data: JSON.stringify(params),
        beforeSend: (request)=> {
          request.setRequestHeader("Authorization", this.$store.state.token);
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success:  (res)=> {
          console.log(res)
          this.tableData = res.data.items;
          this.trans(this.tableData)
          this.pagination.total = res.data.total;
          this.loading = false;
        }
      })
    },
    // 获取表格勾选数据
    selectionLineChangeHandle(val){
      this.merge = this.$refs.cesTable.multipleSelection
    },
    //查询
    tenantManagementSearch(){
      this.loading = true;
      let params = {
        houseName:this.dataForms.houseName,
        houseId:this.dataForms.houseId,
        houseAddress:this.dataForms.houseAddress,
        pageSize:this.pagination.pageSize,
        pageNo:this.pagination.pageNum
      }
      $.ajax({
        type: 'post',
        dataType:'json',
        contentType:'application/json',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          "Authorization": this.$store.state.token
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true
        },
        async:true,
        url: `${this.$apiPath}/api/urban/warehouse/queryWarehousesPage`,
        data: JSON.stringify(params),
        beforeSend: (request)=> {
          request.setRequestHeader("Authorization", this.$store.state.token);
          //  request.setRequestHeader("token", store.state.token);

        },
        success:  (res)=> {
          console.log(res)
          this.tableData = res.data.items || [];
          this.trans(this.tableData)
          this.pagination.total = res.data.total;
          this.loading = false;
        }
      })
    },
    tenantManagementSearch_Multiple(){
      this.pagination.pageSize = 10;
      this.pagination.pageNum = 1;
      this.pagination.total = 0;
      this.tenantManagementSearch();
    },
    //新增
    tenantManagementAdd(){
      this.isFlag = false;
      this.dialogtenantAPPovaltitile = '新增库房';
      this.dialog_tenantAPPoval = true;
      this.tenantEnumFlag = false;
    },
    //公司
    companyFocus(){
      this.companySearch();
      this.dialogTableVisible = true;
    },
    //修改
    tenantManagementModify(){
      this.isFlag = false;
      this.selectionLineChangeHandle();
      if(this.merge.length == 0){
        this.$message({
          type: 'info',
          message: '修改操作必须选中一条数据'
        });
      }else if(this.merge.length > 1){
        this.$message({
          type: 'info',
          message: '修改操作只能选择单条数据进行操作'
        });
      }else{
        this.dialogtenantAPPovaltitile = '修改库房';
        this.dialog_tenantAPPoval = true;
        this.tenantEnumFlag = true;
        axios.get(`${this.$apiPath}/api/urban/warehouse/findWarehouse/`+ this.merge[0].houseId).then(res=>{
          if(res.data.data.status == true){
            res.data.data.status = '1';
          }else if(res.data.data.status == false){
            res.data.data.status = '0';
          }
          if(res.data.data.chintBusiness == null){
            res.data.data.chintBusiness = '';
          }else {
            res.data.data.chintBusiness = JSON.stringify(res.data.data.chintBusiness);
          }
          if(res.data.data.outGoing == null){
            res.data.data.outGoing = '';
          }else {
            res.data.data.outGoing = JSON.stringify(res.data.data.outGoing);
          }
          if(res.data.data.regionLevel == null){
            res.data.data.regionLevel = '';
          }else {
            res.data.data.regionLevel = JSON.stringify(res.data.data.regionLevel);
          }
          res.data.data.moduleId = JSON.stringify(res.data.data.moduleId);
          this.tenantAPPovalForm = res.data.data;
          this.tenantAPPovalForm.province = res.data.data.provinceCode;
          this.tenantAPPovalForm.city = res.data.data.cityCode;
          this.tenantAPPovalForm.area = res.data.data.districtCode;
        })
      }
    },
    //删除
    tenantManagementDel(){
      this.selectionLineChangeHandle();
      if(this.merge.length == 0){
        this.$message({
          type: 'info',
          message: '删除操作必须选中一条数据'
        });
      }else if(this.merge.length > 1){
        this.$message({
          type: 'info',
          message: '删除操作只能选择单条数据进行操作'
        });
      }else{
        this.$confirm('此操作将对库房进行删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.get(`${this.$apiPath}/api/urban/warehouse/deleteWarehouse/`+ this.merge[0].houseId).then(res=>{
            if(res.data.success == true){
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.tenantManagementSearch();
            }else {
              this.$alert(res.data.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      }
    },
    handleClose() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false;
        }, 400);
        this.tenantManagementSearch_Multiple()
        // 动画关闭需要一定的时间
        this.dialog = false;
      }, 2000);
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.tenantAPPovalForm.province = '';
      this.tenantAPPovalForm.city = '';
      this.tenantAPPovalForm.area = '';
      clearTimeout(this.timer);
    },
    handleClosButtonClick(val) {
      this.dialogVisibleMenu=true;
      this.addDialogVisible = true;
      this.houseId=val.index.houseId;
      this.queryMenuByRoleId();
    },
    //查询所有菜单树
    getMenuTree(){
      axios.post(`${this.$apiPath}/api/pm/menu/queryMenusAllTree/`).then(res=>{
        this.menuTreeList= res.data;
      })
    },
    //查询要回显的菜单树
    queryMenuByRoleId(){
      axios.get(`${this.$apiPath}/api/pm/menu/queryMenuByRoleId/`+ this.houseId).then(res=>{
        const tenantMenu=res.data;
        const checkedMenuIds=[];
        for(let i=0;i<tenantMenu.length;i++){
          if(tenantMenu!=null && tenantMenu.length>0){
            checkedMenuIds.push(tenantMenu[i].menuId);
            if(tenantMenu[i].menuId.split("_").length - 1 == 0){
              this.expandTreeNodesByDefault.push(tenantMenu[i].menuId)
            }else if(tenantMenu[i].menuId.split("_").length - 1 == 3){
              this.expandTreeNodesByDefault.push(tenantMenu[i].menuId.slice(0,-2))
            }
          }
        }
        this.$refs.tree.setCheckedKeys(checkedMenuIds);
      })
    },
    //新增分配菜单
    handleSaveMenu() {
      const checkedMenu = this.$refs.tree.getCheckedNodes();
      const checkedMenuIds = [];
      for (let i = 0; i < checkedMenu.length; i++) {
        checkedMenuIds.push(checkedMenu[i].menuId);
      }
      this.$confirm('是否要分配该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = new URLSearchParams();
        params.append("houseId", this.houseId);
        params.append("menuIds", checkedMenuIds);
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/pm/menu/updateRoleMenus`,
          data:params
        }).then(res=> {
          if(res.data.success == true){
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          }else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            });
          }
          this.dialogVisibleMenu = false;
          this.$refs.tree.setCheckedKeys([])
        });
      })

    },
    handleClearMenu(){

    }
  }
}
</script>
<style>
.tableMain .ces-table-page{
  height: 100%;
}
.tableMain .ces-table{
  height: 100%;
}
.tableMain .el-table th{
  padding: 0;
  height: 35px;
}
.tableMain .el-table td{
  padding: 0;
  height: 35px;
}
</style>
<style scoped>
.boxbutton{
  width: 100%;
  height: 40px;
}
.tableMain{
  width: 100%;
  height: 82%;
}
</style>
